import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: 1,
};

const timeTabSlice = createSlice({
  name: "timeTabSlice",
  initialState,
  reducers: {
    setActive: (state, payload) => {
      state.activeTab = payload.payload;
    },
  },
});

export const { setActive } = timeTabSlice.actions;

export default timeTabSlice.reducer;
