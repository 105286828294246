import { Grid } from "antd";
import React from "react";

const MainLayout = ({ children }) => {
  const screens = Grid.useBreakpoint();
  console.log(screens);

  return (
    <div
      className={
        !screens.md
          ? "main-layout mobile"
          : !screens.lg
          ? "main-layout tablet"
          : "main-layout"
      }
    >
      {children}
    </div>
  );
};

export default MainLayout;
