import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const authToken = localStorage.getItem("gToken");

  return authToken ? (
    <>
      <Navigate to="/" />
    </>
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
