import { Spin } from "antd";
import React from "react";

const LoadingDiv = () => {
  return (
    <div className="loader-div">
      <Spin size="large" />
    </div>
  );
};

export default LoadingDiv;
