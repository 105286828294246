import { configureStore } from "@reduxjs/toolkit";
import timeTabReducer from "./slices/timeTabSlice";
import msisdnReducer from "./slices/msisdnSlice";

export default configureStore({
  reducer: {
    timeTabReducer: timeTabReducer,
    msisdnReducer
  },
});
